.svmpw-connectedstatus{
    display:flex;
    align-items: center;
    margin-top: 10px;
}
.svmpw-connectionstatus-content{
margin:auto;

}
.svmpw-connectedstatus-text {
    flex:20px 0 0;
    vertical-align: middle;
    padding:10px;
    width: auto;
    color:#fff;
    font-size: 19px;
}

.circle {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: whiteSmoke;
  }

.realtime-disconnected {
    box-shadow: 1px -10px 15px 2px #FF634D inset;  
    vertical-align: middle;
}

.realtime-connected {
    box-shadow: 1px -10px 15px 2px #17E8D2 inset;
    vertical-align: middle;
} 
  
.spin {
    animation: spin 1s infinite linear;
}

@keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
}

.elastic-spin {
    animation: elastic-spin 1s infinite ease;
}
@keyframes elastic-spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(720deg); }
}

  