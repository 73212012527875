.svmpw-header {
    width: 100%;
    height: auto;
    padding:0 20px;
    box-sizing: border-box;
}

.svmpw-header-content {
    max-width: 1280px;
    min-height: 180px;
    height: auto;
    padding: 20px 0px;
    margin: 0 auto;
    box-sizing: border-box;
    overflow: hidden;
}

.text-logo {
    position: absolute;
    top:20px;
    left:40px;
    user-select: none;
    line-height: normal;
}
.logo-line1 {
    color:#fff; 
    font-size: 22pt;
    font-weight: 700;
    text-align: start;
}
.logo-line2 {
    color:#17E8D2; 
    margin-top: -20px;
    margin-left: -2px;;
    font-size: 36pt;
    font-weight: 700;
    text-align: start;
}
.logo-line3 {
    color:rgb(255,99,77); 
    letter-spacing: 2px;
    margin-top: -22px;
    font-size: 42pt;
    font-weight: 800;
    text-align: center;
}

@media screen and (max-width: 500px) {
    .svmpw-header-content{
        max-width: 1280px;
        min-height: 0px;
    }

    .svmpw-logo{
        width: 150px;
        margin-top: 5px;
    }
    .logo-line1 {
        font-size: 10pt;
    }
    .logo-line2 {
        margin-top:-5px;
        font-size: 20pt;
    }
    .logo-line3 {
        margin-top:-10px;
        font-size: 24pt;
    }

    
}

