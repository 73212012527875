
.container {
  position: absolute;
  width:100%;
  height: 100%;
    box-sizing: border-box;
    display: block;
    z-index: 1;
}
.container h1 {
    text-align: center;
}


.progressbar-container {
    position: relative;
    margin:auto;
    width: 98%;
    height: 128px;
    border: 0px solid #FFF;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
  .progressbar-complete {
    position: absolute;
    left: 0;
    top: 0px;
    height: 100%;
    background-color: #5225bd;
    border-radius: 10px;
    animation: g 2500ms infinite ease-in-out;
    z-index: 2;
  }

  .progressbar-liquid {
    z-index: 1;
    width: 70px;
    height: 70px;
    animation: g 2500ms infinite ease-in-out, r 3000ms infinite cubic-bezier(0.5, 0.5, 0.5, 0.5);
    position: absolute;
    right: -5px;
    top: -10px;
    background-color: #5225bd;
    border-radius: 40%;
  }

  .progress {
    z-index: 2;
    font-size: 20px;
  }

  @keyframes g {
    0% { background-color: #5225bd; }
    50% { background-color: #607e9e; }
    100% { background-color: #5225bd; }
  }
  
  @keyframes r {
    from { transform: rotate(0deg); }
    from { transform: rotate(360deg); }
  }