.svmpw-playlist {
    flex:70px 1 0;
    min-height: 362px;
    padding:10px;
    position: relative;
    box-sizing: border-box;
    border: 2px solid #999999;
    border-radius: 10px;
    padding: 10px 10px;
    background: #1c1c1c;
    color:#fff;
    font:100%/1 "Droid Sans", "Lucida Sans Unicode", "Lucida Grande", Verdana, Arial, Helvetica, sans-serif;
    display: flex;
    flex-direction: column;
}

.svmpw-playlist h3 {
    margin-top:0px;
}
.svmpw-playlist-tracks {
    flex-grow:1;

}

.svmpw-playlist-loadtrackbutton {
    width:140px;
    height: 40px;
    margin:auto;
}

@media screen and (max-width: 768px){
    .svmpw-playlist {
    
        margin: 4px;
        min-width: 350px;
        min-height: 100px;
    }

}