/* @font-face {
    font-family: "DSEG15ClassicMini";
    src: url('/fonts/DSEG14ClassicMini-Regular.woff2');
}
@font-face {
    font-family: "DSEG15ModernMini";
    src: url('/fonts/DSEG14ModernMini-Regular.woff2');
} */


.svmpw-audioplayer-container {
    width: 100%;
    box-sizing: border-box;
    color:#fff;
    border-radius: 10px;
    min-width: 650px;
    padding: 5px;
}

.svmpw-audioplayer-header {
    display: flex;
    justify-content: space-between;
    align-items: center;  
    height: 48px;
    margin:0 8px;
    background-color: var(--display-background-color);
    padding:3px 8px;
}
.svmpw-audioplayer-tracktitle {
    display:flex;
    align-items: center;
    justify-content: center;
}
.svmpw-audioplayer-tracknumber{
    margin-bottom: 3px;
}
.svmpw-audioplayer-tracktitle h1 {
    padding:0px 10px;
    color: var(--digit-color);
    font-size: 36px;
    /* font-family: "DSEG15ClassicMini"; */
    Font-variant: small-caps;
    line-height: 36px;
    margin:auto;
}
.svmpw-audioplayer-formatdisplays {
    display:flex;
    margin-right: 40px;
    font-size: 16px;
    flex-direction: column;

}
.svmpw-audioplayer-timedisplays {
    display: flex;
    align-self: flex-end;
    font-size: 3em;
    color: var(--digit-color);
    align-items: center;
}
.svmpw-audioplayer-timedisplays-separator {
    margin-right: 3px;
}
.svmpw-audioplayer-display {
    border-left: 2px lightgray solid;
    border-right: 2px lightgray solid;
}

.svmpw-audioplayer-controls {
    display: flex;
    justify-content: space-around;
}

.svmpw-audioplayer-controls-sync {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.svmpw-audioplayer-controls-player {
    display: flex;
	align-items: center;
}
.svmpw-audioplayer-controls-loudness {
    display: flex;
	align-items: center;
}


.svmpw-audioplayer-controls button {
    margin:10px;
    cursor: pointer;
}

.svmpw-audioplayer-controls-synctoggle {
    margin:10px;
}

.svmpw-audioplayer-controls-volume {
    color:#fff;
    padding:0px 0px;
    width:160px;
}

.svmpw-waveform {
    box-sizing: border-box;
    position: relative;
    width:100%;
    height:150px;
    padding-top:10px;
    background-color:#222;
    box-shadow: inset 0px 0px 10px 0px rgba(0,0,0,1);

}
#waveform {
    margin:auto;
    width:98%;
    height: 90%;

}

.glass_effect
{
 border: 2px solid #CCCCCC;
 margin: 15px;
 box-shadow: 0px 0px 0px 8px rgba(0, 0, 0, 0.3), 
             0px 15px 8px 0px rgba(0, 0, 0, 0.6); 
}

@media screen and (max-width: 768px){

}