/* dark color 272e38 - looks like a turned off segment when on black background, could be nicer than the opacity switch */
:root {
	--dark-color:#272e38;
	--light-color:#0CC;
	--display-background-color:#000;
	--dot-color: var(--light-color);
	--digit-color: var(--light-color);
}

#time.light{
	background-color:transparent;
	color:var(--digit-color)infinite alternate; 
}

#time.light:after{
	box-shadow:0 4px 10px rgba(0,0,0,0.15);
}

#time.light .digits div span{
	background-color:var(--digit-color);
	border-color:var(--digit-color);	

}

#time.light .digits div.dots:before,
#time.light .digits div.dots:after{
	background-color:var(--dot-color);

	animation: flicker 1.5s 
}

#time .digits div {
    text-align:left;
    position: relative;
    width: 18px;
    height: 36px;
    display:inline-block;
    margin:4px 4px -4px 0;
}

#time .digits div span {
    opacity:0;
    position: absolute;

 	-webkit-transition:0.25s;
	-moz-transition:0.25s;   
    transition:0.25s; 
}

#time .digits div span:before,
#time .digits div span:after {
    content:'';
    position:absolute;
    width:0;
    height:0;
    border: 5px solid transparent;
}

#time .digits .d1  { height:3px;width:10px;top:0;left:4px;}
#time .digits .d1:before {border-width:0 3px 3px 0;border-right-color:inherit;left:-3px;}
#time .digits .d1:after {border-width:0 0 3px 3px;border-left-color:inherit;right:-3px;}

#time .digits .d2{			height:3px;width:10px;top:15px;left:4px;}
#time .digits .d2:before{	border-width:2px 3px 1px;border-right-color:inherit;left:-7px;}
#time .digits .d2:after{	border-width:2px 3px 1px;border-left-color:inherit;right:-7px;}

#time .digits .d3{			height:3px;width:10px;top:30px;left:4px;}
#time .digits .d3:before{	border-width:3px 3px 0 0;border-right-color:inherit;left:-3px;}
#time .digits .d3:after{	border-width:3px 0 0 3px;border-left-color:inherit;right:-3px;}

#time .digits .d4{			width:3px;height:8px;top:4px;left:0;}
#time .digits .d4:before{	border-width:0 3px 3px 0;border-bottom-color:inherit;top:-3px;}
#time .digits .d4:after{	border-width:0 0 3px 3px;border-left-color:inherit;bottom:-3px;}

#time .digits .d5{			width:3px;height:8px;top:4px;right:0;}
#time .digits .d5:before{	border-width:0 0 3px 3px;border-bottom-color:inherit;top:-3px;}
#time .digits .d5:after{	border-width:3px 0 0 3px;border-top-color:inherit;bottom:-3px;}

#time .digits .d6{			width:3px;height:8px;top:21px;left:0;}
#time .digits .d6:before{	border-width:0 3px 3px 0;border-bottom-color:inherit;top:-3px;}
#time .digits .d6:after{	border-width:0 0 3px 3px;border-left-color:inherit;bottom:-3px;}

#time .digits .d7{			width:3px;height:8px;top:21px;right:0;}
#time .digits .d7:before{	border-width:0 0 3px 3px;border-bottom-color:inherit;top:-3px;}
#time .digits .d7:after{	border-width:3px 0 0 3px;border-top-color:inherit;bottom:-3px;}

#time .digits div.dots{
	width:3px;
}

#time .digits div.dots:before,
#time .digits div.dots:after{
	width:3px;
	height:3px;
	content:'';
	position:absolute;
	left:0;
	top:8px;
}

#time .digits div.dots:after{
	top:24px;
}


/* Animate neon flicker */
@keyframes flicker {
    
    0%, 19%, 21%, 23%, 25%, 54%, 56%, 100% {
      
        text-shadow:
            -0.2rem -0.2rem 1rem #fff,
            0.2rem 0.2rem 1rem #fff,
            0 0 2rem var(--neon-text-color),
            0 0 4rem var(--neon-text-color),
            0 0 6rem var(--neon-text-color),
            0 0 8rem var(--neon-text-color),
            0 0 10rem var(--neon-text-color);
        
        box-shadow:
            0 0 .5rem #fff,
            inset 0 0 .5rem #fff,
            0 0 2rem var(--neon-border-color),
            inset 0 0 2rem var(--neon-border-color),
            0 0 4rem var(--neon-border-color),
            inset 0 0 4rem var(--neon-border-color);        
    }
    
    20%, 24%, 55% {        
        text-shadow: none;
        box-shadow: none;
    }    
}