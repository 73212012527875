.svmpw-playlist-item {
    color:#fff;
    cursor: pointer;
    padding:2px 0px;
    display: flex;
    width: 100%;
}

.playlist-item {
    color:var(--container-color);;
    font-weight: bold;
    cursor: pointer;
    width:95%;
    display: grid;
    grid-template-columns: auto 50px 50px;
    overflow: hidden;
    height: 16px;
}
.playlist-item > .right {
    text-align: right;
}

.playlist-item-selected {
    color:#17E8D2;
    font-weight: bolder;
}