.syncedplayer {
    display: flex;
    padding: 50px;
}

.syncedplayer-leftcolumn {
    flex:350px 0 0;
    display: flex;
    flex-direction: column;
    margin:0 10px

}
.syncedplayer-rightcolumn {
    flex:320px 1 0;
    margin:0 10px
}

@media screen and (max-width: 768px){
    .syncedplayer {
        flex-direction: column;
    }
    .syncedplayer-rightcolumn {
        flex:120px 1 0;
        order :1;
    }
    .syncedplayer-leftcolumn{
        flex:150px 0 0;
        order:2;
    }

}

@media screen and (max-width: 500px){
}